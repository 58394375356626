body {
  margin: 0;
  font-family: Gilroy;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Gilroy;
}

.bar-inv {
  font-size: 16px;
  color: #ffffff;
  line-height: 19px;
  padding: 11px 16px;
  background-color: #0b1a47;
  padding: 10px;
  width: 134px;
  height: 41px;
  text-align: center;
  border-radius: 10px;
  position: absolute;
  margin: 11px 16px;
  left: -80px;
  top: -65px;
}

.bar-inv::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #0b1a47;
  bottom: -4px;
  transform: rotate(45deg);
  left: 48%;
  border-radius: 2px;
}

.bar-pro {
  width: 100%;
  height: 2px;
  background-color: #c9cbd0;
  margin-top: 80px;
}

.bar-inner {

  height: 2px;
  background-color: #0b1a47;
  position: relative;
  width: 50%;

}

.bar-inner span {
  position: absolute;
  width: 14px;
  height: 14px;
  border: 2px solid #0b1a47;
  background-color: #ffffff;
  border-radius: 50%;
  right: 0;
  bottom: -5px;
}

.bro-per {
  display: flex;
  justify-content: space-between;
}

.bro-per p {
  font-size: 16px;
  text-align: center;
  margin-top: 7px;
}

.fees-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin: 100px 0;
}

.fees-all {
  width: 382px;
  height: 100%;
  background-color: #eceff3;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid transparent;
}

.f1-p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.f1-p p {
  font-size: 20px;
  margin-bottom: 30px;
  color: #0b1a47;
}

.fees-br {
  border: 1px solid #050917;
  opacity: 0.2;
  width: 100%;
  margin-bottom: 28px;
}

.fees-pri {
  font-size: 16px;
  color: #050917;
  margin-bottom: 35px;
}

.fees-pri span {
  font-size: 45px;
  color: #050917;
  margin-right: 5px;
}

.fees-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.fees-total p {
  font-size: 16px;
  color: #050917;
}

.fees-total h2 {
  font-size: 25px;
  color: #050917;
}

.fees-det {
  margin-bottom: 20px;
}

.light-p {
  font-size: 16px;
  color: #0b1a47;
  opacity: 0.5;
  margin-bottom: 10px;
}

.dark-p {
  font-size: 16px;
  color: #0b1a47;
}

.fix-p {
  max-width: 279px;
}

.fees-tik {
  display: flex;
}

.fees-btn {
  background-color: #ffbe3d;
  color: #0b1a47;
  font-size: 16px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  padding: 14px 0px;
  margin-top: 57px;
}

.fees-fir-btn {
  width: 156px;
  font-size: 16px;
  color: #ffffff;
  background-color: #224dcf;
  padding: 13px 20px;
  border-radius: 5px;
  margin-bottom: 19px;
  text-align: center;
}

.fees-fou {
  padding-top: 20px;
}

.fees-all:hover {
  border: 2px solid #224dcf;
}

.fees-all:focus {
  background-color: #0b1a47;
  border: none;
  color: #c9cbd0;
}

.active-pricing {
  background-color: #0B1A47;

}

.active-pricing .f1-p p {
  color: #F9F8F2;
}

.active-pricing .fees-br {
  border-color: #FFFFFF;
  opacity: 0.2;
}

.active-pricing .fees-pri {
  color: #F9F8F2;
}

.active-pricing .fees-pri span {
  color: #F9F8F2;
}

.active-pricing .fees-total p {
  color: #F9F8F2;
}

.active-pricing .fees-total h2 {
  color: #F9F8F2;
}

.active-pricing .light-p {
  color: #F9F8F2;
  opacity: 0.5;

}

.active-pricing .dark-p {
  color: #F9F8F2;
}

@media (max-width: 1620px) {
  .fees-box {
    justify-content: space-evenly;
  }

  .fees-all {
    flex-basis: calc(43.33% - 20px);
  }

  .container {
    max-width: 94%;
  }
}

@media (max-width: 768px) {
  .fees-all {
    flex-basis: calc(100% - 20px);
  }
}

@media (max-width: 576px) {
  .fees-all {
    flex-basis: 100%;
  }
}